import { Link } from '@chakra-ui/next-js';
import {
  Button,
  Grid,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { navLinks } from '@/shared/constants';
import { ArrowDown } from '@/shared/ui';

const DesktopMenu = () => (
  <Grid
    alignItems='center'
    gap='md'
    gridArea='menu'
    templateColumns='auto auto'
  >
    {navLinks.map(({ title, items, href }) => {
      if (items) {
        return (
          <Menu
            key={title}
            autoSelect={false}
            placement='top'
          >
            {({ isOpen }) => (
              <>
                <MenuButton
                  alignItems='center'
                  as={Button}
                  display='flex'
                  fontWeight='normal'
                  rightIcon={
                    <ArrowDown
                      boxSize={4}
                      transform={isOpen ? 'rotate(0.5turn)' : 'rotate(1turn)'}
                      transition='0.3s'
                    />
                  }
                  variant='unstyled'
                >
                  {title}
                </MenuButton>
                <MenuList
                  bg='bg.light'
                  border='none'
                  display='flex'
                  gap='sm'
                  p='md'
                  rootProps={{
                    maxW: '36rem',
                    transform: 'translateX(-50%) !important',
                    inset: '3rem 0 0 50% !important',
                  }}
                >
                  {items.map(({ name, description, Icon, href }) => (
                    <MenuItem
                      key={name}
                      alignItems='start'
                      as={Link}
                      borderRadius='md'
                      flexDir='column'
                      gap='md'
                      href={href}
                      p='sm'
                      w={{ base: '14rem', lg: '16rem' }}
                    >
                      <Icon
                        boxSize={8}
                        color='primary.pure'
                        strokeWidth='1.5'
                      />
                      <Heading
                        fontWeight='semibold'
                        size='md'
                      >
                        {name}
                      </Heading>
                      <Text color='text.medium'>{description}</Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        );
      }
      return (
        <Link
          key={title}
          href={href!}
        >
          {title}
        </Link>
      );
    })}
  </Grid>
);

export default DesktopMenu;
